import axios from "utils/axios";

export const getSocmeds = () => {
  return axios.get("/hr/recruitments/socmeds");
};

export const getLocations = () => {
  return axios.get("/hr/recruitments/locations");
};

export const getCompanySizes = () => {
  return axios.get("/hr/recruitments/companySizes");
};

export const getCompanyPhotos = () => {
  return axios.get("/hr/recruitments/companyPhotos");
};

export const getCompany = () => {
  return axios.get("/hr/recruitments/companies");
};

export const addCompany = () => {
  return axios.post(`/hr/recruitments/companies`);
};

export const editCompany = (data) => {
  return axios.put(`/hr/recruitments/companies`, data);
};

export const uploadCompanyPhotos = (data) => {
  return axios.post(`/hr/recruitments/uploadCompanyPhotos`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadCompanyGalleries = (photos) => {
  return axios.post(`/hr/recruitments/companyPhotos`, {photos});
};

export const deleteCompanyPhoto = (id) => {
  return axios.delete(`/hr/recruitments/companyPhotos/${id}`);
};
