import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import * as api from "apis";
import {message} from "antd";

export function useRecruitmentCandidates({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/candidates", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRecruitmentCandidates(payload);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useRecruitmentCandidateById({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/candidate/id", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRecruitmentCandidateById(payload);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useExecuteMassDeleteRecruitmentCandidates = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeMassDeleteRecruitmentCandidates(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddRecruitmentCandidate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addRecruitmentCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditRecruitmentCandidate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editRecruitmentCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUploadRecruitmentAttachment = () => {
  return useMutation((payload) => api.uploadCandidateAttachment(payload.job_id, payload.data), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

export const useDeleteCandidate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useSendMessageCandidate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.sendMessageCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export function useCandidateNotes({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/candidate/id/note", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getNoteCandidate(payload);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddCandidateNote = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addNoteCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditCandidateNote = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editNoteCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteCandidateNote = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteNoteCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export function useCandidateHistoryActivity({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/candidate/id/history-activity", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getHistoryActivityCandidate(payload.id, payload.params);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

