import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import {message} from "antd";

import * as api from "apis/recruitmentCompanyProfile";
import {useDispatch} from "react-redux";
import {setSetting} from "../redux/configSlice";

export function useCompanyProfile(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["company_profile"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCompany();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddCompanyProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation(() => api.addCompany(), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        dispatch(setSetting({ sekerja_company_id: "1" }));
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditCompanyProfile = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editCompany(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export function useCompanyLocations(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["company_locations"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLocations();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useCompanySocmeds(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["company_socmeds"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSocmeds();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useCompanySizes(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["company_sizes"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCompanySizes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useCompanyGallery(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["company_gallery"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCompanyPhotos();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useDeleteGallery = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteCompanyPhoto(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUploadCompanyGalleries = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.uploadCompanyGalleries(payload), {
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
