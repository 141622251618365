import { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import { UndoOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { Cancel, Save } from "components/UI";

const SignaturePad = ({
  innerRef,
  visible,
  name,
  payloadSignature,
  onUploadSignature,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const uploadSignatureSalarySlip = onUploadSignature();
  const { isLoading: loadingUploadSalarySlip } = uploadSignatureSalarySlip;

  const [isError, setIsError] = useState(false);

  const submitHandler = useCallback(async () => {
    const isEmpty = innerRef?.current?.isEmpty();
    if (isEmpty) {
      setIsError(true);
      return;
    } else {
      setIsError(false);
      const imageURL = innerRef?.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png");
      if (imageURL) {
        const blob = await fetch(imageURL).then((res) => res.blob());
        const convertedFile = new File([blob], `${name}.png`, {
          type: "image/png",
        });

        const formData = new FormData();
        formData.append("file", convertedFile);
        const payload = {
          ...payloadSignature,
          data: formData,
        };
        uploadSignatureSalarySlip.mutate(payload, {
          onSuccess: (response) => {
            if (response?.data?.success) {
              onSave(name, response?.data?.data);
              onClose();
            }
          },
        });
      }
    }
  }, [
    innerRef,
    name,
    uploadSignatureSalarySlip,
    payloadSignature,
    onSave,
    onClose,
  ]);

  const clearHandler = useCallback(() => {
    innerRef?.current?.clear();
  }, [innerRef]);

  useEffect(() => {
    if (visible) {
      setIsError(false);
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      title={t("layoutSalarySlip.signature")}
      onCancel={onClose}
      destroyOnClose
      footer={[
        <div
          key="signature-pad-footer"
          className="flex flex-row justify-between items-center"
        >
          <Cancel onClick={onClose} />
          <div className="flex flex-row gap-x-2">
            <Button
              icon={<UndoOutlined />}
              onClick={clearHandler}
              disabled={!!loadingUploadSalarySlip}
            >
              {t("settings.reset")}
            </Button>
            <Save
              onClick={submitHandler}
              loading={loadingUploadSalarySlip}
              disabled={!!loadingUploadSalarySlip}
            />
          </div>
        </div>,
      ]}
    >
      <div className="flex items-center justify-center mb-5">
        <SignatureCanvas
          penColor="black"
          ref={innerRef}
          canvasProps={{
            height: 450,
            width: 450,
            className: "border border-gray-300 rounded-md shadow-md",
          }}
        />
      </div>
      {!!isError && (
        <div className="ml-3">
          <Typography.Text className="text-red1">
            {t("layoutSalarySlip.please_enter_a_signature")}
          </Typography.Text>
        </div>
      )}
    </Modal>
  );
};

export default forwardRef((props, ref) => (
  <SignaturePad innerRef={ref} {...props} />
));
