import {Form} from "antd";
import {Editor as RdwEditor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {useMemo} from "react";
import {css} from "@emotion/css";

import TagOption from "components/apps/Template/Content/TagOption";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {uploadCompanyPhotos} from "apis";

const Editor = ({name, label, tags, rules, body, setBody, editorStyle, toolbarOptions}) => {
  const toolbarCustom = useMemo(() => {
    const toolbars = [];
    if (tags) {
      toolbars.push(<TagOption data={tags}/>);
    }
    return toolbars;
  }, [tags]);

  const customClass = css`
    .rdw-image-modal-url-input {
      background-color: white !important;
    }

    .rdw-image-modal-size-input {
      background-color: white !important;
    }
    
    .rdw-image-modal-alt-input {
      background-color: white !important;
    }

    .rdw-option-wrapper {
      color: black !important;
    }
  `;

  const uploadImageCallback = (file) => {
    const formData = new FormData();
    formData.append("photo", file);

    return uploadCompanyPhotos(formData)
      .then(response => {
        const {url} = response.data.data;
        return {data: {link: url}};
      })
      .catch(error => {
        console.error("Error uploading image:", error);
        return Promise.reject(error);
      });
  };

  return (
    <div className={customClass}>
      <Form.Item
        name={name}
        label={label}
        getValueFromEvent={(e) =>
          !e?.blocks[0]?.text
            ? null
            : draftToHtml(convertToRaw(body.getCurrentContent()))
        }
        rules={rules}
      >
        <RdwEditor
          editorState={body}
          onEditorStateChange={setBody}
          toolbarCustomButtons={toolbarCustom}
          toolbar={{
            options: toolbarOptions,
            inline: {
              options: ["bold", "italic", "underline", "strikethrough"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
            image: {
              uploadCallback: uploadImageCallback,
              alt: {present: true, mandatory: false},
              previewImage: true,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            },
          }}
          toolbarStyle={{
            border: "1px solid #ced4da",
            borderRadius: "2px",
            marginBottom: 0,
          }}
          toolbarClassName="bg-transparent text-gray-900"
          editorStyle={{
            paddingLeft: ".75rem",
            paddingRight: ".75rem",
            border: "1px solid #ced4da",
            borderTop: 0,
            borderRadius: "2px",
            ...editorStyle,
          }}
        />
      </Form.Item>
    </div>
  );
};

Editor.defaultProps = {
  editorStyle: {minHeight: "300px"},
  toolbarOptions: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "history",
    "emoji",
    "image",
  ],
};

export default Editor;
