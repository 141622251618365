import axios from "utils/axios";
import {stringify} from "query-string";

export const getRecruitmentJobs = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/recruitments/jobs?${query}`);
};

export const getRecruitmentJobById = (id) => {
  return axios.get(`/hr/recruitments/jobs/${id}`);
};

export const getJobStages = (id) => {
  return axios.get(`/hr/recruitments/jobs/${id}/stages`);
};

export const getJobCategories = () => {
  return axios.get("/hr/recruitments/jobCategories");
};

export const getJobTypes = () => {
  return axios.get("/hr/recruitments/jobTypes");
};

export const getStudyMajors = () => {
  return axios.get("/hr/recruitments/studyMajors");
};

export const getWorkingTypes = () => {
  return axios.get("/hr/recruitments/workingTypes");
};

export const getJobExperiences = () => {
  return axios.get("/hr/recruitments/experiences");
};

export const getJobQualifications = () => {
  return axios.get("/hr/recruitments/qualifications");
};

export const getSalaryCurrencies = () => {
  return axios.get("/hr/recruitments/currencies");
};

export const getJobStatuses = () => {
  return axios.get("/hr/recruitments/jobStatuses");
};

export const getJobRegistration = (id) => {
  return axios.get(`/hr/recruitments/jobs/${id}/registrationForms`);
};

export const addJobDescription = (data) => {
  return axios.post("/hr/recruitments/jobs", data);
};

export const addJobRegistration = (data) => {
  return axios.post(`/hr/recruitments/jobs/${data?.id}/registrationForms`, data);
};

export const editJobRegistration = (data) => {
  return axios.put(`/hr/recruitments/jobs/${data?.id}/registrationForms`, data);
};

export const addJobStage = (data) => {
  return axios.post(`/hr/recruitments/jobs/${data?.id}/stages`, data?.data);
};

export const moveJobStage = (data) => {
  return axios.patch(`/hr/recruitments/jobs/${data.jobId}/stages/${data.stageId}/move`, data?.data);
};

export const deleteJobStage = (data) => {
  return axios.delete(`/hr/recruitments/jobs/${data.jobId}/stages/${data.stageId}`);
};

export const editRecruitmentJobById = (data) => {
  return axios.put(`/hr/recruitments/jobs/${data.id}`, data);
};

export const draftJob = (data) => {
  return axios.patch(`/hr/recruitments/jobs/${data?.id}/draft`);
}

export const publishJob = (data) => {
  return axios.patch(`/hr/recruitments/jobs/${data?.id}/publish`);
}

export const closeJob = (data) => {
  return axios.patch(`/hr/recruitments/jobs/${data?.id}/close`);
}

export const getRecruitmentJobCandidatesByStage = (jobId, stageId, params) => {
  const query = stringify(params);
  return axios.get(`/hr/recruitments/jobs/${jobId}/stages/${stageId}/pagination?${query}`);
};

export const favoriteCandidate = (data) => {
  return axios.patch(`/hr/recruitments/jobs/${data.job_id}/candidates/${data.id}/favorite`, data);
}

export const rejectCandidate = (data) => {
  return axios.patch(`/hr/recruitments/jobs/${data.job_id}/candidates/${data.id}/reject`, data);
}

export const moveCandidate = (jobId, candidateId, data) => {
  return axios.patch(`/hr/recruitments/jobs/${jobId}/candidates/${candidateId}/move`, data);
}
