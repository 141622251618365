import {stringify} from "query-string";
import axios from "utils/axios";

export const getRecruitmentCandidates = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/recruitments/jobCandidates/pagination?${query}`);
};

export const executeMassDeleteRecruitmentCandidates = (data) => {
  return axios.delete('/hr/recruitments/jobCandidates/massDelete', { data })
};

export const addRecruitmentCandidate = (data) => {
  return axios.post(`/hr/recruitments/jobs/${data?.job_id}/candidates`, data);
};

export const editRecruitmentCandidate = (data) => {
  return axios.patch(`/hr/recruitments/jobCandidates/${data?.id}`, data);
}

export const uploadCandidateAttachment = (jobId, data) => {
  return axios.post(`/hr/recruitments/jobs/${jobId}/candidates/upload`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getRecruitmentCandidateById = (id) => {
  return axios.get(`/hr/recruitments/jobCandidates/${id}`);
};

export const sendMessageCandidate = (data) => {
  return axios.post(`/hr/recruitments/jobCandidates/${data?.id}/message`, data);
};

export const getNoteCandidate = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/recruitments/jobCandidates/${params?.jobCandidateId}/note/pagination?${query}`);
};

export const addNoteCandidate = (data) => {
  return axios.post(`/hr/recruitments/jobCandidates/${data?.id}/note`, data);
};

export const editNoteCandidate = (data) => {
  return axios.patch(`/hr/recruitments/jobCandidates/${data?.id}/note/${data?.note_id}`, data);
}

export const deleteNoteCandidate = (data) => {
  return axios.delete(`/hr/recruitments/jobCandidates/${data?.id}/note/${data?.note_id}`);
};

export const deleteCandidate = (data) => {
  return axios.delete(`/hr/recruitments/jobCandidates/${data.id}`);
};

export const getHistoryActivityCandidate = (id, params) => {
  const query = stringify(params);
  return axios.get(`/hr/recruitments/jobCandidates/${id}/activity/pagination?${query}`);
};


