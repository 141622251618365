import React, {useMemo} from 'react';
import { Modal, Button, Row, Col, Input, message, Typography, Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Facebook from "assets/svg/facebook.svg";
import Twitter from "assets/svg/twitter.svg";
import Linkedin from "assets/svg/linkedin.svg";
import Telegram from "assets/svg/telegram.svg";
import Whatsapp from "assets/svg/whatsapp.svg";
import {useTranslation} from "react-i18next";

const { Title } = Typography;

const ShareModal = ({ title, visible, onCancel, data, pageLink, ...props }) => {
  const { t } = useTranslation();

  const formattedLink = useMemo(() => {
    if (!pageLink) {
      return '';
    }
    return pageLink;
  }, [pageLink]);

  const handleCopy = () => {
    navigator.clipboard.writeText(pageLink);
    message.success(t('modal.link_copied'));
  };

  const handleShare = (platform) => {
    let url = '';
    switch (platform) {
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${formattedLink}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${formattedLink}`;
        break;
      case 'whatsapp':
        url = `https://api.whatsapp.com/send?text=${formattedLink}`;
        break;
      case 'telegram':
        url = `https://t.me/share/url?url=${formattedLink}`;
        break;
      case 'twitter':
        url = `https://twitter.com/share?url=${formattedLink}`;
        break;
      default:
        break;
    }
    window.open(url, '_blank');
  };

  const buttonStyle = {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
  };

  const colStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <Modal
      title={<Title level={4}>{title}</Title>}
      open={visible}
      onCancel={onCancel}
      footer={null}
      centered
      {...props}
    >
      <Row justify="space-between" className="mt-6">
        <Col style={colStyle}>
          <Button
            shape="square"
            icon={<img src={Linkedin} alt="linkedin logo" />}
            style={buttonStyle}
            className="border-[#0077B5] bg-[#0077B5]/10 hover:opacity-70"
            onClick={() => handleShare('linkedin')}
          />
          <div style={{ textAlign: 'center' }}>LinkedIn</div>
        </Col>
        <Col style={colStyle}>
          <Button
            shape="square"
            icon={<img src={Facebook} alt="facebook logo" />}
            style={buttonStyle}
            className="border-[#1877F2] bg-[#1877F2]/10 hover:opacity-70"
            onClick={() => handleShare('facebook')}
          />
          <div style={{ textAlign: 'center' }}>Facebook</div>
        </Col>
        <Col style={colStyle}>
          <Button
            shape="square"
            icon={<img src={Whatsapp} alt="whatsapp logo" />}
            style={buttonStyle}
            className="border-[#29A71A] bg-[#29A71A]/10 hover:opacity-70"
            onClick={() => handleShare('whatsapp')}
          />
          <div style={{ textAlign: 'center' }}>WhatsApp</div>
        </Col>
        <Col style={colStyle}>
          <Button
            shape="square"
            icon={<img src={Telegram} alt="telegram logo" />}
            style={buttonStyle}
            className="border-[#039BE5] bg-[#039BE5]/10 hover:opacity-70"
            onClick={() => handleShare('telegram')}
          />
          <div style={{ textAlign: 'center' }}>Telegram</div>
        </Col>
        <Col style={colStyle}>
          <Button
            shape="square"
            icon={<img src={Twitter} alt="twitter logo" />}
            style={buttonStyle}
            className="border-[#03A9F4] bg-[#03A9F4]/10 hover:opacity-70"
            onClick={() => handleShare('twitter')}
          />
          <div style={{ textAlign: 'center' }}>Twitter</div>
        </Col>
      </Row>
      <div style={{ marginTop: 24 }}>
        <Title level={5} className="text-sm">Page Link</Title>
        <Space.Compact style={{ width: '100%' }}>
          <Input
            rootClassName="font-light dark:text-white text-black"
            value={formattedLink}
            disabled
          />
          <Button onClick={handleCopy} type="primary"><CopyOutlined /></Button>
        </Space.Compact>
      </div>
    </Modal>
  );
};

export default ShareModal;
