import {useTranslation} from "react-i18next";
import {useInfiniteQuery, useMutation, useQuery} from "react-query";
import * as api from "apis"
import {message} from "antd";

export function useRecruitmentJobs({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRecruitmentJobs(payload);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useRecruitmentJobById({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/job/id", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRecruitmentJobById(payload);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useRecruitmentJobSuggestion = (
  { payload = {} },
  options = {}
) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["recruitmentJobSuggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRecruitmentJobs({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export function useJobStages({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/stages", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobStages(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useJobCategories(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/categories"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobCategories();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useJobTypes(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/types"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobTypes();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useStudyMajors(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/studyMajors"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getStudyMajors();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useWorkingTypes(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/workingTypes"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getWorkingTypes();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useJobExperiences(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/experiences"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobExperiences();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useJobQualifications(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/qualifications"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobQualifications();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useSalaryCurrencies(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/currencies"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalaryCurrencies();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useJobStatuses(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/statuses"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobStatuses();
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useJobLocations(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/jobs/locations"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLocations();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useJobRegistration({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/job/id/registration", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobRegistration(payload);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useAddJobDescription = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addJobDescription(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddJobRegistration = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addJobRegistration(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddJobStage = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addJobStage(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useMoveJobStage = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.moveJobStage(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteJobStage = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteJobStage(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditJobDescription = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editRecruitmentJobById(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditJobRegistration = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editJobRegistration(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const usePublishJob = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.publishJob(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDraftJob = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.draftJob(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useCloseJob = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.closeJob(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export function useRecruitmentJobCandidatesByStage({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["recruitments/job/id/candidates", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRecruitmentJobCandidatesByStage(payload.jobId, payload.stageId, payload.params);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useInfiniteJobCandidatesByStage = (
  { payload = {} },
  options = {}
) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["infinite/job/candidates", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getRecruitmentJobCandidatesByStage(payload.jobId, payload.stageId, { page: pageParam, ...payload.params });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFavoriteCandidate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.favoriteCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useRejectCandidate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.rejectCandidate(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useMoveCandidate = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.moveCandidate(payload.job_id, payload.id, payload.data), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
