import { Select } from "antd";
import { useCallback } from "react";
import { EditorState, Modifier } from "draft-js";
import { useTranslation } from "react-i18next";

const TagOption = ({ loading, data, editorState, onChange }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value) => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        `[${value}]`,
        editorState.getCurrentInlineStyle()
      );
      const newEditorState = EditorState.push(editorState, contentState);

      onChange(
        EditorState.forceSelection(
          newEditorState,
          newEditorState.getSelection()
        )
      );
    },
    [editorState, onChange]
  );

  return (
    <div className="w-[200px] ml-auto my-2 mr-2">
      <Select
        loading={loading}
        placeholder={t("template.insert_tags")}
        options={(data || []).map((row) => ({
          value: row?.name,
          label: row?.title,
        }))}
        showSearch
        filterOption={(input, option) =>
          (option?.label.toLocaleLowerCase() ?? "").includes(
            input.toLocaleLowerCase()
          )
        }
        onSelect={handleChange}
      />
    </div>
  );
};

export default TagOption;
