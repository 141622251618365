import { memo, useCallback, useMemo, useState } from "react";
import { Button, message, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { CloudUploadOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { stringify } from "query-string";
import PropTypes from "prop-types";

import { generateEndpoint, getDimensions } from "utils/helper";
import { getToken } from "utils/cookies";

const UploadSignature = ({
  data,
  name,
  params,
  mimeType,
  maxWeight,
  maxWidth,
  maxHeight,
  action,
  headers,
  onSubmit,
  onBefore,
  onAfterSuccess,
  onAfterFailed,
  ...props
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const defaultHeaders = useMemo(() => {
    return {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    };
  }, [headers]);

  const formattedAction = useMemo(() => {
    if (params) {
      const query = stringify(params);
      return `${action}?${query}`;
    }
    return action;
  }, [params, action]);

  const uploadAction = useMemo(
    () => generateEndpoint(formattedAction),
    [formattedAction]
  );

  const beforeUploadHandler = useCallback(
    async (file) => {
      //execute function onBefore
      onBefore();

      const isJpgOrPng = mimeType.includes(file.type);
      if (!isJpgOrPng) {
        message.error(t("property.you_can_only_upload_jpg_or_png_file"));
      }
      const isLt1M = file.size / 1024 / 1024 <= maxWeight;
      if (!isLt1M) {
        message.error(
          `${t("property.image_must_smaller_than")} ${maxWeight}MB!`
        );
      }

      let isMaxWidth = false;
      let isMaxHeight = false;
      const dimensions = await getDimensions(file);
      if (dimensions) {
        isMaxWidth = dimensions.width <= maxWidth;
        if (!isMaxWidth) {
          message.error(
            `${t("property.image_width_must_smaller_than")} ${maxWidth}px!`
          );
        }

        isMaxHeight = dimensions.height <= maxHeight;
        if (!isMaxHeight) {
          message.error(
            `${t("property.image_height_must_smaller_than")} ${maxHeight}px!`
          );
        }
      }

      return new Promise((resolve, reject) => {
        if (isJpgOrPng && isLt1M && isMaxWidth && isMaxHeight) {
          return resolve(true);
        }
        return reject;
      });
    },
    [maxHeight, maxWeight, maxWidth, mimeType, t, onBefore]
  );

  const changeHandler = useCallback(
    async ({ file }) => {
      if (file.status === "uploading") {
        setLoading(true);
        return;
      }
      if (file.status === "done") {
        message.success(
          `${file.name} ${t("property.file_uploaded_successfully")}`
        );
        // const filePreview = await getBase64(file.originFileObj);
        const fileURL = file.response?.data;
        onSubmit(fileURL);
        // onSetImagePreview(filePreview);
        setLoading(false);
        onAfterSuccess();
      } else if (file.status === "error") {
        message.error(`${file.name} ${t("property.file_upload_failed")}`);
        setLoading(false);
        onAfterFailed();
      }
    },
    [t, onSubmit, onAfterFailed, onAfterSuccess]
  );

  return (
    <Upload
      className={`w-full ${css`
        .ant-upload-select {
          width: 100% !important;
        }
      `}`}
      showUploadList={false}
      accept={mimeType}
      name={name}
      headers={defaultHeaders}
      action={uploadAction}
      beforeUpload={beforeUploadHandler}
      onChange={changeHandler}
      disabled={!!loading}
      data={data}
      {...props}
    >
      <Button
        loading={loading}
        icon={<CloudUploadOutlined className="text-primary text-base" />}
        className="w-full"
      >
        <span className="text-primary">
          {t("layoutSalarySlip.upload_signature")}
        </span>
      </Button>
    </Upload>
  );
};

UploadSignature.propTypes = {
  mimeType: PropTypes.string,
  maxWeight: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  action: PropTypes.string,
  name: PropTypes.string,
  paramsName: PropTypes.string,
  headers: PropTypes.object,
  onSubmit: PropTypes.func,
  onBefore: PropTypes.func,
  onAfterFailed: PropTypes.func,
  onAfterSuccess: PropTypes.func,
};

UploadSignature.defaultProps = {
  name: "file",
  mimeType: "image/jpeg, image/png",
  maxWeight: 10,
  maxWidth: 3000,
  maxHeight: 3000,
  action: "",
  headers: {
    "X-app": "hr",
  },
  onSubmit: () => {},
  onBefore: () => {},
  onAfterFailed: () => {},
  onAfterSuccess: () => {},
};

export default memo(UploadSignature);
