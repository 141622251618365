import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";

import { message } from "antd";
import * as api from "apis/employee";
import { useTranslation } from "react-i18next";

//Query untuk get all employees
export function useEmployees({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["employees", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployees(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

export const useEmployeeSuggestion = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["employees/suggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployees({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

//Query untuk get employee personal identity pagination
export function useEmployeesPersonalIdentity({
  payload = {},
  enabled = false,
}) {
  const { t } = useTranslation();
  return useQuery(
    ["employees/personal_identity", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeesPersonalIdentity(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get employee personal contact pagination
export function useEmployeesPersonalContact({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["employees/personal_contact", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeesPersonalContact(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get employee career pagination
export function useEmployeesCareer({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["employees/career", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeesCareer(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get employee payroll pagination
export function useEmployeesPayroll({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["employees/payroll", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeesPayroll(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get employee login ESS pagination
export function useEmployeesLoginEss({ payload = {}, enabled = false }) {
  const { t } = useTranslation();
  return useQuery(
    ["employees/ess", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeesLoginEss(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      enabled,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get detail employee
export function useEmployee({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["employee", payload],
    async () => {
      let response = {};
      try {
        if (payload && payload.id) {
          const {
            data: { data },
          } = await api.getEmployee(payload.id);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) => {
        // message.error(t("error.failed_to_load_data_from_server"));
      },
      ...options,
    }
  );
}

//Query untuk get max date started work
export function useDateStartedWorkMax({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["employee/dateStartedWorkMax", payload],
    async () => {
      let response = {};
      try {
        if (payload && payload.id) {
          const {
            data: { data },
          } = await api.getDateStartedWorkMax(payload.id);
          response = data;
        }
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get total employee
export function useEmployeesTotal(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["employee/total"],
    async () => {
      let response = {};
      try {
        const {
          data: { data },
        } = await api.getEmployeesTotal();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//query untuk get employee code
export const useEmployeesCode = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["employees/code", payload],
    async () => {
      let response = {};
      try {
        const {
          data: { data },
        } = await api.getEmployeesCode(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

// Query untuk add employee personal
export const useAddEmployeePersonal = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addEmployeePersonal(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk add employee career
export const useAddEmployeeCareer = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addEmployeeCareer(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk add employee payroll
export const useAddEmployeePayroll = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addEmployeePayroll(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit personal identity
export const useEditPersonalIdentity = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPersonalIdentity(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit personal contact
export const useEditPersonalContact = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPersonalContact(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit personal education
export const useEditPersonalEducation = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPersonalEducation(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit date started work employee
export const useEditDateStartedWork = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editDateStartedWork(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit payroll npwp
export const useEditPayrollNpwp = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPayrollNpwp(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit payroll bpjs
export const useEditPayrollBpjs = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPayrollBpjs(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit payroll bank account
export const useEditPayrollBankAccount = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPayrollBankAccount(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete employee
export const useDeleteEmployee = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((id) => api.deleteEmployee(id), {
    onSuccess: ({ data }, id) => {
      if (data?.success) {
        message.success(data?.message);
        queryClient.removeQueries(["employee", { id }]);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk add employee termination
export const useAddEmployeeTerminations = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addEmployeeTermination(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk rehire employee termination
export const useRehireEmployeeTermination = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.rehireEmployeeTermination(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete employee termination
export const useDeleteEmployeeTerminations = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteEmployeeTermination(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query update photo profile employee
export const useUpdateEmployeePhoto = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updateEmployeePhoto(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query update attachemnt employee
export const useUpdateEmployeeAttachment = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.updateEmployeeAttachment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query reset password employee
export const useResetEmployeePassword = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.resetEmployeePassword(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query update signature employee
export const useUpdateSignatureEmployee = () => {
  const { t } = useTranslation();
  return useMutation((data) => api.updateSignatureEmployee(data), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query update signature employee
export const useUploadSignatureEmployee = () => {
  const { t } = useTranslation();
  return useMutation((data) => api.uploadSignatureEmployee(data), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query  upload import employee
export const useUploadImportEmployee = () => {
  return useMutation((payload) => api.uploadImportEmployee(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query  execute import employee
export const useExecuteImportEmployee = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportEmployee(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query  upload import update employee
export const useUploadImportUpdateEmployee = () => {
  return useMutation((payload) => api.uploadImportUpdateEmployee(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query  execute import update employee
export const useExecuteImportUpdateEmployee = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportUpdateEmployee(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
