import { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignatureOutlined } from "@ant-design/icons";
import { FaRegTrashAlt } from "react-icons/fa";
import { Button, Popconfirm, Popover } from "antd";
import { css } from "@emotion/css";

import { Skeleton } from "components/UI";
import UploadSignature from "components/UI/Signature/UploadSignature";
import SignaturePad from "components/UI/Signature/SignaturePad";
import classname from "classname";

const OptionContent = ({
  name,
  onSave,
  onHide,
  onDirectSignature,
  uploadSignatureProps,
}) => {
  const { t } = useTranslation();

  const [loadingUpload, setLoadingUpload] = useState(false);

  const beforeHandler = useCallback(() => {
    setLoadingUpload(true);
  }, []);

  const directHandler = useCallback(() => {
    onHide();
    onDirectSignature();
  }, [onHide, onDirectSignature]);

  const uploadHandler = useCallback(
    (value) => {
      setLoadingUpload(false);
      onSave(name, value);
      onHide();
    },
    [name, onHide, onSave]
  );

  const afterFailedUploadHandler = useCallback(() => {
    setLoadingUpload(false);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <Button
          type="primary"
          icon={<SignatureOutlined className="text-base" />}
          disabled={loadingUpload}
          onClick={directHandler}
        >
          {t("layoutSalarySlip.direct_signature")}
        </Button>
        <UploadSignature
          onBefore={beforeHandler}
          onSubmit={uploadHandler}
          onAfterFailed={afterFailedUploadHandler}
          {...uploadSignatureProps}
        />
      </div>
    </>
  );
};

const Signature = ({
  name,
  data,
  loading,
  withDefaultSpace = false,
  payloadSignature,
  disabled = false,
  uploadSignatureProps,
  onUploadSignature,
  onSave,
  onReset,
}) => {
  const signatureRef = useRef({});

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [signatureVisible, setSignatureVisible] = useState(false);

  const openHandler = useCallback((newOpen) => {
    setOpen(newOpen);
  }, []);

  const hideHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const showSignatureHandler = useCallback(() => {
    setSignatureVisible(true);
  }, []);

  const hideSignatureHandler = useCallback(() => {
    setSignatureVisible(false);
  }, []);

  const resetSignatureHandler = useCallback(
    (e) => {
      e.stopPropagation();
      if (typeof onReset === "function") {
        onReset();
      }
    },
    [onReset]
  );

  return (
    <>
      <div className="flex flex-col my-2">
        {data?.[name] ? (
          <>
            {loading ? (
              <Skeleton
                avatar={{ shape: "square", className: "h-full w-full" }}
                paragraph={false}
                title={false}
                className="h-[120px] w-[170px]"
                active
              />
            ) : (
              <div
                className={`flex flex-row ${css`
                  &:hover {
                    button {
                      display: block;
                      padding: 0px;
                    }
                  }
                `}`}
              >
                <img
                  alt={`sign_${name}`}
                  className="w-[170px] h-[120px] object-contain"
                  src={`${data?.[name]}?random-number${new Date().getTime()}`}
                />
                {!disabled && (
                  <Popconfirm
                    title={t(
                      "layoutSalarySlip.are_you_sure_to_remove_the_signature"
                    )}
                    trigger={["click"]}
                    onConfirm={resetSignatureHandler}
                  >
                    <button
                      type="text"
                      className={`bg-transparent self-start ${css`
                        display: none;
                      `}`}
                    >
                      <FaRegTrashAlt className="text-primary text-base" />
                    </button>
                  </Popconfirm>
                )}
              </div>
            )}
          </>
        ) : (
          <div
            className={classname("h-[120px]", {
              hidden: !withDefaultSpace,
            })}
          />
        )}
        <div className="flex items-center justify-center mt-2">
          <Popover
            trigger={["click"]}
            content={
              <OptionContent
                name={name}
                uploadSignatureProps={uploadSignatureProps}
                onHide={hideHandler}
                onSave={onSave}
                onDirectSignature={showSignatureHandler}
              />
            }
            open={!disabled ? open : false}
            onOpenChange={openHandler}
          >
            <Button
              disabled={disabled}
              icon={<SignatureOutlined className="text-base" />}
            >
              {t("layoutSalarySlip.signature")}
            </Button>
          </Popover>
        </div>
      </div>
      <SignaturePad
        ref={signatureRef}
        name={name}
        visible={signatureVisible}
        payloadSignature={payloadSignature}
        onUploadSignature={onUploadSignature}
        onClose={hideSignatureHandler}
        onSave={onSave}
      />
    </>
  );
};

export default memo(Signature);
