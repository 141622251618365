import axios from "utils/axios";
import {stringify} from "query-string";

export const getRecruitmentStages = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/recruitments/stages/pagination?${query}`);
};

export const getRecruitmentStageById = (id) => {
  return axios.get(`/hr/recruitments/stages/${id}`);
};

export const addRecruitmentStage = (data) => {
  return axios.post("/hr/recruitments/stages", data);
};

export const editRecruitmentStage = (data) => {
  return axios.put(`/hr/recruitments/stages/${data?.id}`, data);
}

export const deleteRecruitmentStage = (id) => {
  return axios.delete(`/hr/recruitments/stages/${id}`);
};

export const getRecruitmentTemplates = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/recruitments/mailTemplates/pagination?${query}`);
};

export const getRecruitmentTemplateById = (id) => {
  return axios.get(`/hr/recruitments/mailTemplates/${id}`);
};

export const addRecruitmentTemplate = (data) => {
  return axios.post("/hr/recruitments/mailTemplates", data);
};

export const editRecruitmentTemplate = (data) => {
  return axios.put(`/hr/recruitments/mailTemplates/${data?.id}`, data);
}

export const deleteRecruitmentTemplate = (id) => {
  return axios.delete(`/hr/recruitments/mailTemplates/${id}`);
};

export const getRecruitmentTemplateTags = () => {
  return axios.get("/hr/recruitments/mailTemplates/tags");
};

export const addRecruitmentBroadcast = (data) => {
  return axios.post("/hr/recruitments/messages/broadcast", data);
};









